
// React
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

// Supabase
import { getLogDetails, getSchema, getSchemas } from "../../helpers/supabase";


// MaterialUI imports
import {
  Box,
  TextField,
  Button,
  Modal,
  Typography,
  Badge
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';

// SurveyJs
import { Model } from "survey-core";


// Utils
import { toast } from 'react-toastify';
import { Survey } from "survey-react-ui";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const SchemaContentLogs = (props) => {
    const { schemaId } = useParams()
    const sessionArcId = localStorage.getItem('session_arc_id')

    const [searchText, setSearchText ] = useState('')

    const [logList, setLogList ] = useState(null)
    const [filteredLogList, setFilteredLogList ] = useState(null)

    const [schemaList, setSchemaList ] = useState([])
    const [currentSurvey, setCurrentSurvey ] = useState({})

    const [ contentModalVisibility, setContentModalVisibility ] = useState(false)

    async function getLogsByArcId(sessionArcId) {
      try {
          const data = await getLogDetails(sessionArcId, schemaId ? schemaId: null)
          setLogList(data)
          setFilteredLogList(data)
      } catch (error) {
          console.error("Error:", error);
          toast.error('Error Fetching log list', {
              autoClose: 1000,
              position: 'top-right',
          });
      }
    }

    async function getSchemasByArcId(sessionArcId) {
      try {
          const data = await getSchemas(sessionArcId)
          setSchemaList(data)
      } catch (error) {
          console.error("Error:", error);
          toast.error('Error Fetching log list', {
              autoClose: 1000,
              position: 'top-right',
          });
      }
    }

    // Asynchronous Fetching of data from an API with supabase.
    useEffect(() => {
        const fetchData = async () => {
        try {
            await getLogsByArcId(sessionArcId);
            await getSchemasByArcId(sessionArcId);
        } catch (error) {
            console.log('Error in mounting data:', error);
            toast.error('Mounting data error.', {
              autoClose: 1000,
              position: 'top-right',
            }); 
        }
        };
        fetchData();
    }, []); 

    function getSchemaNameByID(schemaID){
      if (schemaList) {
        const schema = schemaList.find(obj => obj.id == schemaID)
        return schema ? schema.name ? schema.name : schema.slug : null
      }
    }

    function getSchemaByID(schemaID){
      if (schemaList) {
        const schema = schemaList.find(obj => obj.id == schemaID)
        return schema ? schema : null
      }
    }

    const columns = [
      { field: 'id', headerName: 'Log ID', width: 100, sortable: true },
      {
        field: 'action',
        headerName: 'Action',
        width: 250,
        sortable: true,
        renderCell: (params) => {
          const action = params.row.action
          return (
            <div style={{marginLeft:'20px'}}>
             {  action && action === 'UPDATE' ? <Badge style={{margin:'5px'}} badgeContent={action} color='info' /> : null }
             {  action && action === 'CREATE' ? <Badge style={{margin:'5px'}} badgeContent={action} color='success' /> : null  }
             {  action && action === 'DELETE' ? <Badge style={{margin:'5px'}} badgeContent={action} color='error' /> : null  }
            </div>
          )
        }
		  },
      {
        field: 'name',
        headerName: 'Schema Name',
        width: 350,
        sortable: true,
        valueGetter: ( params) => {
          return getSchemaNameByID(params.row.schema_id)
        },
		  },

      {
        field: 'prev_content',
        headerName: 'Previous Content',
        width: 150,
        sortable: true,
        renderCell: (params) => {
          const handleViewClick = async (event) => {
            event.stopPropagation(); // Prevent the event from propagating
            handlePrevContentView(params.row.prev_content, params.row.schema_id);
          };

          return (
            <div >
              { params.row.prev_content ?(
                <Button size="small"  color="info" onClick={(e) => handleViewClick(e)}>
                View
                </Button>
              ):<Button size="small"  color="info"  disabled>
                -
                </Button>
              }
            </div>
          )
        }
        
		  },

      {
        field: 'current_content',
        headerName: 'Current Content',
        width: 150,
        sortable: true,
        renderCell: (params) => {
          const handleViewClick = async (event) => {
            event.stopPropagation(); // Prevent the event from propagating
            handleCurrentContentView(params.row.current_content, params.row.schema_id);
          };

          return (
            <div >
              { params.row.current_content ?(
                <Button size="small"  color="info" onClick={(e) => handleViewClick(e)}>
                View
                </Button>
              ):<Button size="small"  color="info"  disabled>
                -
                </Button>
              }
            </div>
          )
        }

		  },
      {
        field: 'created_by',
        headerName: 'Created by',
        width: 250,
        sortable: true,
        valueGetter: ( params) => {
          return params.row.created_by ? params.row.created_by.email : "Composer Embed"
        },
		  },

      {
        field: 'created_at',
        headerName: 'Created at',
        width: 350,
        sortable: true,
        valueGetter: ( params) => {
          const date = new Date(params.row.created_at)
          const options = {
            weekday: 'short',
            year: 'numeric', 
            month: 'short', 
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
          };
          const formatter = new Intl.DateTimeFormat('en-US', options);
          return formatter.format(date);
        },
		  },
	];

  function assignSurvey(survey) {
    // setCurrentContent(prev_content)
    setCurrentSurvey(survey)
  }

  async function handlePrevContentView(prev_content, schemaID){
    toggleContentModalVisibility()

    const schema =  getSchemaByID(schemaID)
    const schemaJson = schema?.schema ? schema.schema : null
    const survey = new Model(schemaJson)
    if (prev_content){
      survey.data = prev_content  
    }else{
      survey.data = null
    }
    assignSurvey(survey)
  }

  async function handleCurrentContentView(current_content, schemaID){
    toggleContentModalVisibility()

    const schema =  getSchemaByID(schemaID)
    const schemaJson = schema?.schema ? schema.schema : null
    const survey = new Model(schemaJson)
    if (current_content){
      survey.data = current_content  
    }else{
      survey.data = null
    }
    assignSurvey(survey)
  }

  function toggleContentModalVisibility(){
    if (contentModalVisibility === true){
      setCurrentSurvey({})
    }
    setContentModalVisibility(!contentModalVisibility)
  }

	function filterLogs(e) {
		setSearchText(e.target.value)
		const searchText = e.target.value
		const filteredLog = logList.filter(item =>{
        return item.id.toString().includes(searchText);      
      }
		  );
		setFilteredLogList(filteredLog);
	}

  return (
		<>
			<h3 style={{
			}}>Logs</h3>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>

				<Box sx={{ flexGrow: 1 }} />
				<TextField
					label="Search by Log ID"
					variant="outlined"
          value={searchText}
					size="small"
					sx={{ width: '300px' }}
          onKeyDown={filterLogs}
					onChange={(e) => filterLogs(e)}
				/>
			</Box>

			<Box id="content-list">
				{ filteredLogList ? 
					<DataGrid
              density="compact"
              sx={{
                minHeight: '200px',
                border: 'none',
                '& .MuiDataGrid-cell': {
                },
                '& .MuiDataGrid-columnHeader': {
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-row': {
                  minHeight: '100px',
                  maxHeight: '100px',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold', 
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none', 
                },
                '& .MuiDataGrid-root .MuiDataGrid-main': {
                  borderRadius: '8px', 
                },
                '& .MuiDataGrid-root': {
                  boxShadow: 'none', 
                },
              }}
              rows={filteredLogList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 20,
                  },
                },
              }}
              pageSizeOptions={[20]}
            /> : null
				}
			</Box>

      <Modal
        open={contentModalVisibility}
        onClose={toggleContentModalVisibility}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box 
           sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: 800,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              overflowY: 'auto', // This makes the content inside the Box scrollable
              maxHeight: '90vh', // Adjust the height as needed to fit within the viewport
            }}
        >
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Content
          </Typography> */}
          <Survey model={currentSurvey} />
        </Box>
      </Modal>

		</>
	)

}

export default SchemaContentLogs