import { useState, useEffect } from 'react'
import SchemaContent from '../components/schemacontent';
import { sendMessage, getEmbedType } from '../helpers/powerup';
import { getSchemas } from '../helpers/supabase';
import { useParams } from 'react-router-dom';

export default function EmbedComposer() {
  const { arcID, schemaID } = useParams();
  const [currentSchema, setCurrentSchema] = useState(null);
  const [message, setMessage] = useState('Loading content...')

  async function fetchData() {
    try {
      const data = await getSchemas(arcID);
      if (parseInt(schemaID)) {
        const findSchema = data.find(x => parseInt(x.id) === parseInt(schemaID))

        findSchema ?
          setCurrentSchema(findSchema)
          : setMessage("Schema not found. Please check the settings of your SchemaBuilder embed.")
      } else {
        setMessage("Invalid Schema Id. Please check the settings of your SchemaBuilder embed.")
      }

    } catch (error) {
      setMessage('Error fetching schemas. Please check your connection.');
      console.error("Error fetching schemas:", error.message);
    }   
    finally {
      
    } 
  }

  useEffect(() => {
    function startUp() {
      sendMessage("ready", {
        height: '300px',
        width: '500px'
      });
     
      //fetchData();
      //getEmbedType();
    }
    startUp();
  }, []);

  return (
    <div style={{ display: 'grid', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
      <div style={{ width: '100%', minWidth:'800px', backgroundColor: '#fff' }}>
        <div className="page-wrapper" style={{ border: '1px solid #F3F3F3', minHeight: '500px', backgroundColor: '#fff' }}>
          <div className="container">
            <div className="row">
              <div>
                <div>
                    <h2>This is a test</h2>
                    <p>arcID:{arcID}</p>
                    <p>schema:{schemaID}</p>
                  {/* {currentSchema ? (
                    <SchemaContent schema={currentSchema} arcId={arcID} style={{ width: '100%' }} />
                  ) : (
                    <div>{message}</div>
                  )} */}
                </div>
               </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}