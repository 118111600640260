import { createUser, createUserOrganization, createUserProfileByUser, getAllOrganization, getUserProfile } from "../helpers/supabase";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid,  } from '@mui/x-data-grid';
import {
	TextField,
	Grid,
	Button,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem
} from "@mui/material";
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';


const UserSection = (props) => {

	const navigate = useNavigate()
  const sessionArcId = localStorage.getItem('session_arc_id')
	const [isLoading, setIsLoading ] = useState(true);
	const [ orgName, setOrgName ] = useState("");

	// state for Org select dropdown.
	const [orgList, setOrgList ] = useState([]);

    const [userData, setUserData] = useState({
        'email':'',
        'org_id': null
    })

	async function fetchOrganization() {
		try {
            const res = await getAllOrganization();
            setOrgList(res)

		} catch (error) {
			console.error("Error:", error);
		}
	}


	useEffect(() => {
        const mount = async () => {
          try {
              await fetchOrganization();
          } catch (error) {
              console.log('Error mounting data', error);
              toast.error('Error mounting data', {
              autoClose: 5000,
              position: 'top-right',
              });
          }
        };
        mount();
     }, []); 

    useEffect(() => {
      if (orgList.length > 0) {
        const org = orgList.filter((o) => o.arc_id === sessionArcId);
        setOrgName(org[0].name)
        if (org.length > 0) {
            setUserData({
                ...userData,
                org_id: org[0].id
            });
        }
      }
    }, [orgList]);


    async function handleCreateUser(e){
      e.preventDefault()
      const user = await createUser(userData.email)

      if(user.user){
        const userProfile = await createUserProfileByUser(user, userData.org_id)
        toast.success('Invite Link sent.', {
          autoClose: 5000,
          position: 'top-right',
          });
      } else {
        toast.error('Email Rate limit(3) exceeded.', {
          autoClose: 5000,
          position: 'top-right',
          });
      }
      
    }

	return (
		<>
			<h3 style={{ marginLeft: '20px'
			}}>Register User for {orgName}</h3>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', margin:'20px' }}>
				
            <form onSubmit={e => handleCreateUser(e)}  style={{ maxWidth: '500px', width:'100%'}}>
            <TextField
              style= {{ marginBottom: '20px'}}
              type="email"
              label="Username (Email)"
              variant="outlined"
              value={userData.email}
              onChange={(e) => setUserData({...userData, email:e.target.value})}
              required
              margin="normal"
              fullWidth
            />
            { orgList &&
                <Select

                    style={{maxWidth:'500px', width:'100%'}}
                    value={userData.org_id}
                    label="Organization"
                    onChange={(e) => setOrganizationID(e.target.value)}
                    required
                >
                    {orgList.map((org) => (
                        <MenuItem key={org.id} value={org.id}>
                            {org.name}
                        </MenuItem>
                    ))}
                </Select>
            }

            <FormHelperText style={{ textAlign: 'left' }}>
              Select the organization's name you'd like to give access to the user.
            </FormHelperText>

            {/* {error && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">{error}</Alert>
              </Stack>
            )} */}

            { userData.email && userData.org_id ?
              <Button fullWidth  variant="contained" color='secondary' type="submit" style={{ marginTop: '16px', backgroundColor: '#ff7545' }}>
                Send Invite
              </Button> : (
              <Button fullWidth disabled={true} variant="contained" color='secondary' type="submit" style={{ marginTop: '16px' }}>
                Send Invite
              </Button> 
              )
            }
          </form>
		</Box>

		</>
	)

}

export default UserSection;