import { getUserProfile } from "../helpers/supabase";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid,  } from '@mui/x-data-grid';
import {
	TextField,
	Grid,
	Button
} from "@mui/material";
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';


const UserSection = (props) => {

	const navigate = useNavigate()
	// state for search Text
	const [searchText, setSearchText ] = useState("");
	const [userList, setUserList ] = useState(null);

	// console.log('schema', schemaList);

	async function fetchUsers() {
		try {
            const res = await getUserProfile();
            console.log('came', res)
            setUserList(res)
		} catch (error) {
			console.error("Error:", error);
		}
	}

	useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUsers();
        console.log('came');
      } catch (error) {
        console.log('Error fetching Schema in dashboard:', error);
        toast.error('Error Fetching Schema in dashboard', {
          autoClose: 5000,
          position: 'top-right',
        });
      }
    };
    fetchData();
  }, []); 

	// async function handleRowSelection(rowId) {
	// 	const schema = schemaList.filter((list) => rowId.includes(list.id))
	// 	navigate(`/admin/designer/${rowId}`)
	// 	// const p = { 'schema': schema[0], 'view': 'design' }
	// 	// onSelectSchema(p); // Call the callback function in the parent (admin)
	// }

	async function handleEdit() {
		// handle edit view here.
	}

	async function handleView() {
		// handle toggle view here.
	}


	async function toggleDeleteModal() {
		setShowDeleteModal(!showDeleteModal)
	}


	// function filterSchema(e) {
	// 	setSearchText(e.target.value)
	// 	const searchText = e.target.value
	// 	const filteredSchema = schemaList.filter(item =>
	// 		item.schema.title.toLowerCase().includes(searchText.toLowerCase())
	// 	  );
	// 	setFilteredSchemaList(filteredSchema)
	// }

	// async function handleDelete() {
	// 	// handle edit view here.
	// 	toggleDeleteModal()
	// 	toast.error(`Schema Deleted`, {
	// 		autoClose: 5000,
	// 		position: 'top-right',

	// 	});
	// 	await deleteSchema(schemaID, arcId);
	// 	const updatedSchemaList = schemaList.filter((schema) => schema.id !== schemaID);
	// 	setSchemaList(updatedSchemaList);
	// 	setAdminSchemaList(updatedSchemaList);
	// }


	const columns = [

		{ field: 'user_id', headerName: 'User ID', width: 200, sortable: true },
		{
			field: 'email',
			headerName: 'Email',
			width: 250,
			sortable: true,
			valueGetter: (params) => {
				return params.row.email
			}
		},
		{
			field: 'phone',
			headerName: 'Phone no.',
			width: 250,
			sortable: true,
			valueGetter: (params) => {
				return params.row.phone ? params.row.phone : '-';
			}
		},
		{
			field: 'organization_name',
			headerName: 'Organization',
			width: 250,
			sortable: true,
			valueGetter: (params) => {
				return params.row.organization ? params.row.organization.name : '-';
			}
		},



		// {
		// 	headerName: 'Actions',
		// 	width: 350,
		// 	sortable: true,
		// 	renderCell: (params) => {
		// 		const handleEditClick = (event) => {
		// 			event.stopPropagation(); // Prevent the event from propagating
		// 			handleEdit(params.row.id);
		// 		};

		// 		const handleViewClick = (event) => {
		// 			event.stopPropagation(); // Prevent the event from propagating
		// 			handleView(params.row.id);
		// 		};
		// 		const handleDeleteClick = (event) => {
		// 			event.stopPropagation(); // Prevent the event from propagating
		// 			// handleDelete(params.row.id);
		// 			console.log('p->', params.row.schema.title)
		// 			let count = calcSchemaContent(params.row.id)
		// 			if (typeof count !== 'number'){
		// 				count=0
		// 			}
		// 			setContentCount(count)
		// 			setConfirmedSchemaName(params.row.schema.title)
		// 			setSchemaID(params.row.id)
		// 			console.log('pc->', confirmedSchemaName)
					
		// 			toggleDeleteModal();
		// 		};
		// 		return (
		// 			<>
		// 				{/* <Button style={{ margin: '5px' }} variant="outlined" color="primary" size='small' onClick={(e) => handleEditClick(e)}>
		// 					Edit
		// 				</Button>
		// 				<Button style={{ marginRight: '5px' }} variant="outlined" color="secondary" size='small' onClick={(e) => handleViewClick(e)}>
		// 					View
		// 				</Button> */}
		// 				<Button variant="outlined" color="error" size='small' onClick={(e) => handleDeleteClick(e)}>
		// 					Delete
		// 				</Button>
		// 			</>
		// 		)
		// 	}
		// },

	];



	return (
		<>
			<h3 style={{
			}}>Users</h3>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>

				<Box sx={{ flexGrow: 1 }} />
				<Grid item>
					<Link to={`/admin/create-user/`}>
						<Button>
							Create User
						</Button>
					</Link>
				</Grid>
				<TextField
					label="Search"
					variant="outlined"
					size="small"
					sx={{ width: '300px' }}
					value={searchText}
					// onKeyDown={filterSchema}
					// onChange={(e) => filterSchema(e)}
				/>
			</Box>

			<Box id="content-list">
				{ userList ? 
					<DataGrid
						sx={{
							minHeight: '200px',
						}}
						rows={userList}
						columns={columns}
						onRowSelectionModelChange={(newRowSelectionModel) => {
							handleRowSelection(newRowSelectionModel);
						}}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 25,
								},
							},
						}}
						pageSizeOptions={[5]}
					/> : null
				}

			</Box>
			{/* <div>
				<Dialog  open={showDeleteModal} onClose={toggleDeleteModal}>
					<DialogTitle>Delete Schema "{confirmedSchemaName}"</DialogTitle>
					<DialogContent>
						<DialogContentText>
							"{confirmedSchemaName}" contains {contentCount} contents. <br/>
							Are you sure you want to delete this Schema?
						</DialogContentText>
						<TextField
							label="Enter Schema name."
							variant="outlined"
							size="small"
							sx={{ width: '400px', marginTop:'10px' }}
							value={schemaName}
							onChange={(e) => setSchemaName(e.target.value)} 
						/>
					</DialogContent>
					<DialogActions style={{padding: '20px'}}>
						<Button onClick={toggleDeleteModal} color="primary">
							Cancel
						</Button>
						<Button onClick={handleDelete} disabled={isDeleteDisabled} variant="contained" color="error" autoFocus>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</div> */}
		</>
	)

}

export default UserSection;