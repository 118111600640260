import Navigation from '../components/navigation';


export default function Header(props) {
    const {arcId, schemaList, setSchemaList} = props
    return (
        <div className='nav'>
            <Navigation arcId={arcId} schemaList={schemaList} setSchemaList={setSchemaList}/>
        </div>
    )
}