
// React imports
import { useEffect, useState, useCallback, useRef } from "react";
import { Link } from 'react-router-dom'

// SurveyJS imports
import { Survey } from 'survey-react-ui';
import PropTypes from "prop-types";
import { Model } from 'survey-core';

// supabase imports
import { addLogDetails, deleteContent, getContent, getSchema, getSingleContentNoSchema, updateContent } from "../helpers/supabase";
import { sendMessage, getKey } from "../helpers/powerup.js";

// MaterialUI imports
import {
  Stack,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  LinearProgress
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';


// Utils
import '../assets/styles/defaultV2.css';
// import { checkUserRole } from '../helpers/utilities';
import { toast } from 'react-toastify';
import { Close } from "@mui/icons-material";

export default function SchemaContent(props) {
  const { schema, arcId, content, contentId, source } = props;
  const [localSchema, setLocalSchema] = useState(schema)
  const createdBy = localStorage.getItem('org_id')
  const surveyJson = schema?.schema ? schema?.schema : null;
  const survey = surveyJson ? new Model(surveyJson) : null;

  useEffect(() => {
    const getUpdatedSchemaById = async () => {
      const latestSchema = await getSchema(schema.id, arcId)
      setLocalSchema(latestSchema)
    }
    getUpdatedSchemaById()
  }, []);



  const contentIdRef = useRef(null)
  if (contentId) {
    contentIdRef.current = contentId;
  }
  const schemaDisplayFieldRef = useRef(null)
  const actionRef = useRef(null)
  const prevContentRef = useRef(null)

  const [roleChecker, setRoleChecker] = useState(false);

  const [contentlist, setContentList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const [currentSchemaData, setCurrentSchemaData] = useState(content)
  const [isEditScreen, setIsEditScreen] = useState(false)
  const [surveyView, setSurveyView] = useState(null)

  // delete modal states
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteContentID, setDeleteContentID] = useState(0)


  useEffect(() => {
    const viewConfig = async () => {
      if (currentSchemaData) {
        const view = await ShowEditScreenView();
        setSurveyView(view);
        setIsEditScreen(true);
      } else {
        setIsEditScreen(false);
      }
    }
    viewConfig()

  }, [currentSchemaData]);

  const handleAddNewContent = () => {
    setShowDetails(true);
    setSurveyView(<ShowDetailsView />);
    contentIdRef.current = null
  }

  function toggleDeleteModal() {
    setShowDeleteModal(!showDeleteModal)
  }

  async function handleDelete() {
    // handle content delete func here.
    actionRef.current = 'DELETE'

    toggleDeleteModal()
    toast.success(`Record Deleted`, {
      autoClose: 1000,
      position: 'top-right',
    });

    await deleteContent(deleteContentID, arcId);

    const updatedContentList = contentlist.filter((content) => content.id !== deleteContentID);
    setContentList(updatedContentList);

    const logData = {
      content_id: deleteContentID,
      arc_id: arcId,
      schema_id: schema.id,
      prev_content: prevContentRef.current.content,
      created_by: createdBy,
      action: actionRef.current
    }
    await createLog(logData)

  }

  const handleRowSelection = async (id) => {
    console.log('ROW SELECTION')
    const _id = id[0]
    const content = await getSingleContentNoSchema(id, arcId)
    const singleContent = content && content.length > 0 ? content[0] : null
    const ansCustomEmbed = {
      id: getKey(),
      url: '/',
      config: {
        "contentId": { _id },
        "content": { singleContent }
      }
    }
    sendMessage('data', ansCustomEmbed)
  }

  const handleRowClick = async (obj) => {
    console.log('ROW CLICK')
    contentIdRef.current = obj.id // content id
    await toggleFormView(obj);
  }

  const handleCancel = () => {
    setShowDetails(false);
    setIsEditScreen(false);
    setCurrentSchemaData(null);
    contentIdRef.current = null
  }

  SchemaContent.propTypes = {
    schema: PropTypes.object,
  };

  const getDisplayField = ((fieldString) => {
    try {
      if (fieldString) {
        schemaDisplayFieldRef.current = fieldString.split(',').map(item => item.trim());
      } else {
        schemaDisplayFieldRef.current.push('name')
      }
    } catch (e) {
      console.log('ERROR in assigning Field Array: ', e)
    }
  })

  async function fetchData() {
    try {
      const latestSchema = await getSchema(schema.id, arcId)
      const displayField = latestSchema[0].schema.displayField
      if (displayField) {
        getDisplayField(displayField)
      }
      const data = await getContent(schema.id, arcId);
      setContentList(data);

      dynamicColumnConfig()

    } catch (error) {
      console.error("Error:", error);
    }
  }

  function updatedDataKeys(newData, prevData) {
    let changedKeys = []
    for (let key in newData) {
      if (newData?.hasOwnProperty(key) && prevData?.hasOwnProperty(key)) {
        if (newData[key] !== prevData[key]) {
          changedKeys.push(key);
        }
      }
    }
    return changedKeys
  }

  const surveyComplete = useCallback(async (sender) => {

    const contentID = contentIdRef.current

    //update content in the database
    const response = await updateContent(sender.data, contentID, arcId, schema.id);
    console.log('RESPONSE: ', JSON.stringify(response))

    const logData = {
      prev_content: actionRef.current === 'CREATE' ? null : prevContentRef.current,
      current_content: sender.data,
      content_id: actionRef.current === 'CREATE' ? response[0].id : contentID,
      arc_id: arcId,
      schema_id: schema.id,
      action: actionRef.current,
      created_by: createdBy
    }

    await createLog(logData)

    //update content in the Arc XP story
    if (source === 'EMBED') {
      const _id = response[0].id;
      const singleContent = response[0];

      const ansCustomEmbed = {
        id: getKey(),
        url: '/',
        config: {
          "contentId": { _id },
          "content": { singleContent }
        }
      }
      console.log('sending message', JSON.stringify(ansCustomEmbed));
      sendMessage('data', ansCustomEmbed)
    } else {
      await fetchData();
      setShowDetails(false);
      setIsEditScreen(false)
      toast.success(`Success`, {
        autoClose: 1000,
        position: 'top-right',
      });
    }


    /* const updatedFieldList = updatedDataKeys(sender.data, prevContentRef.current)

    if (updatedDataKeys.length !== 0) {
      const updatedContentData = {
        action: 'UPDATE',
        id: contentID,
        updatedFields: updatedFieldList,
        content: sender.data
      }


      if(schema.id === 23 || schema.id === 25){
        // Call your API endpoint with the updated schema
        fetch('https://wqqbdkfzqthhozyoiwko.supabase.co/functions/v1/post_data', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'client_id': '49314b62fc8c4051a5fcdcc5a77a6669',
            'client_secret': '1E5c768CE4cc40d59033Ecc91d8B7f51'
          },
          body: JSON.stringify({updatedContent: updatedContentData, schemaType: schema.name}),
        })
        .then(response => {
          if (response.ok) {
            console.log('Schema updated successfully in external API');
            console.log(response.data);
          } else {
            console.error('Failed to update schema in external API');
          }
        })
        .catch(error => {
          console.error('Error updating schema in external API:', error);
        });
      } 
    }*/

  }, []);

  if (survey) {
    survey.showCompletedPage = false;
    if (survey.onComplete) {
      survey.onComplete.add(surveyComplete);
    }
  }

  useEffect(() => {
    fetchData();
  }, [schema]);

  useEffect(() => {
    async function checkAdminAndUserRole() {
      //const status = await checkUserRole(['A', 'U']);
      //TODO fix this later, but need to be able to set everyone to be able to edit right now b/c of embed
      //setRoleChecker(status);
      setRoleChecker(true);
    }
    checkAdminAndUserRole();
  }, []);

  const createLog = (async (logData) => {
    const res = await addLogDetails(logData)
  })

  const formatField = (fieldName) => {
    if (fieldName.includes('_')) {
      return fieldName.split('_').map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ');
    } else {
      // return fieldName.charAt(0).toUpperCase() + fieldName.slice(1).toLowerCase();

      // For camelCase, insert a space before uppercase letters followed by lowercase letters
      const withSpaces = fieldName.replace(/([a-z])([A-Z])/g, '$1 $2');
      // Then capitalize the first letter of each word
      return withSpaces.split(' ').map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ');
    }
  }


  const [columns, setColumns] = useState([
    { field: 'id', headerName: 'ID', width: 190, sortable: true },
  ]);

  // for dynamic column
  const dynamicColumnConfig = () => {
    setColumns([
      { field: 'id', headerName: 'ID', width: 190, sortable: true },
    ])
    const newColumns = [...columns]

    if (schemaDisplayFieldRef.current) {
      schemaDisplayFieldRef.current.forEach(fieldName => {
        const newColumn = createColumn(fieldName)
        newColumns.push(newColumn)
      })
    }

    const actionColumn = {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      sortable: true,
      lock: true,
      renderCell: (params) => {
        const handleDeleteClick = (event) => {
          event.stopPropagation(); // Prevent the event from propagating
          setDeleteContentID(params.row.id)
          //setConfirmedContentName(params.row.name)
          prevContentRef.current = params.row
          toggleDeleteModal()
        };
        return (
          <>
            {source === "UI" ? (<Button variant="outlined" color="error" size='small' onClick={(e) => handleDeleteClick(e)}>
              Delete
            </Button>) : null}

          </>
        )
      },
    }

    newColumns.push(actionColumn)
    setColumns(newColumns)
  }


  const createColumn = (fieldName) => {
    const headerName = formatField(fieldName)
    const column = {
      field: fieldName,
      headerName: headerName,
      width: 190, // You can adjust the width as needed
      sortable: true,
      valueGetter: (params) => params.row.content ? params.row.content[fieldName] : '',
      renderCell: (params) => {
        return params.row.content && params.row.content[fieldName]
          ? <>{params.row.content[fieldName]}</> : <Close color="error" />
      }
    };
    return column
  }


  const ShowDetailsView = () => {
    actionRef.current = 'CREATE'

    return (
      <Box id="content-detail" >
        <Stack>
          <Box>
            <Survey model={survey} />
          </Box>
        </Stack>
      </Box>
    )
  }

  /////CHECK THIS

  const ShowEditScreenView = async () => {
    actionRef.current = 'UPDATE'
    try {
      const contentData = currentSchemaData ? currentSchemaData : await getSingleContentNoSchema(currentSchemaData.id, arcId) // get single content (comes array

      if (contentData) {
        survey.data = currentSchemaData.content // contentData.length && contentData[0].content
        prevContentRef.current = survey.data
      }

      return (
        <Box id="content-detail" >
          <Stack>
            <Box>
              {survey.data ? <Survey model={survey} /> : <LinearProgress />}
            </Box>
          </Stack>
        </Box>
      )
    } catch (e) {
      console.log("Error in configuring ShowEditScreenView.", { e })
      toast.error(`Cannot configure Edit Screen View`, {
        autoClose: 1000,
        position: 'top-right',
      });
    }

  }


  async function toggleFormView(obj) {
    setCurrentSchemaData(obj);
  }

  return (
    <>
      <Box style={{
        width: '100%',
        bg: '#fff',
        borderRadius: '10px',
        verticalAlign: 'middle',
        paddingTop: '20px',
        height: 'auto',
        overflow: 'auto'
      }}>

        <Stack style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ justifySelf: 'flex-start' }}>

          </div>
          <div style={{ justifySelf: 'flex-end' }}>
            {source === "UI" ? (<Button
              onClick={() => handleCancel()}
              size="small"
              variant="outlined" style={{ display: showDetails || isEditScreen ? 'block' : 'none' }}
            >Cancel & Return to Content List</Button>) : null}

            {roleChecker ? (
              <div style={{ height: '50px' }}>
                <Grid container spacing={1} alignItems="center" justify="flex-start">
                  {source === "UI" ? (<Grid item>
                    <Link to={`/admin/designer/${schema && schema.id}/logs`}>
                      <Button
                        style={{ display: showDetails || isEditScreen ? 'none' : 'block' }}
                      >
                        Logs
                      </Button>
                    </Link>
                  </Grid>) : null}

                  <Grid item>
                    <Button
                      onClick={() => handleAddNewContent()}
                      size="small"
                      variant="outlined"
                      style={{ display: showDetails || isEditScreen ? 'none' : 'block' }}
                    >
                      Add New Content
                    </Button>
                  </Grid>
                </Grid>
              </div>

            ) : null}
          </div>
        </Stack>
        <Stack>
          <Box id="content-list" style={{ display: showDetails || isEditScreen ? 'none' : 'block', width: '100%', height: '100%', marginTop: '10px' }}>
            {contentlist.length > 0 ?
              <DataGrid
                sx={{
                  minHeight: '200px',
                  border: 'none',
                  '& .MuiDataGrid-cell': {
                  },
                  '& .MuiDataGrid-columnHeader': {
                    borderBottom: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    minHeight: '100px',
                    maxHeight: '100px',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-root .MuiDataGrid-main': {
                    borderRadius: '8px',
                  },
                  '& .MuiDataGrid-root': {
                    boxShadow: 'none',
                  },
                }}
                headerFilter={{ visible: true, allowSearch: true }}
                onRowClick={(params, event) => {
                  handleRowClick(params.row);
                }}
                rows={contentlist}
                columns={columns}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  handleRowSelection(newRowSelectionModel);
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[25]}
              // filterModel={filterModel}
              // onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
              /> :
              <div className="sd-body sd-body--empty">The schema doesn't contain any records.</div>
            }
          </Box>
        </Stack>
        <div style={{ display: showDetails || isEditScreen ? 'block' : 'none' }}>
          {surveyView}
        </div>
      </Box>

      <Dialog open={showDeleteModal} onClose={toggleDeleteModal}>
        <DialogTitle>Delete Record</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button onClick={x => toggleDeleteModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={(x) => handleDelete()} variant="contained" color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>

  );
}
