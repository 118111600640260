
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { updateSchemaTemplate } from "../helpers/supabase"
import { useEffect } from "react";

export default function SchemaSettings(props) {
  const { schema, arcId } = props;
  function handleClick() {
    const response = updateSchemaTemplate(document.getElementById("template").value, schema.id, arcId);
  }

  useEffect(() => {
    document.getElementById('template').value = schema.template;
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        paddingTop: '50px', 
      }}
    >
    <div style={{
            maxWidth: '1000px', // Adjust the maximum width as needed
            width: '100%',
            padding: '0 0px', 
        }}
      >
      <Stack>
        <Box css={{
          width: '100%',
          height: '300px',
          bg: '$gray100',
          textAlign: 'center',
          borderRadius: '10px',
          verticalAlign: 'middle',
          paddingTop: '50px'
        }}>
          <Stack spacing={3}>
          <div style={{fontWeight: 'bolder', fontSize: '28px'}} className="sd-title sd-container-modern__title"> HTML TEMPLATE </div>

            <Box>
              <div className="form-group">
                  <textarea style={{width: '100%'}} id="template" name="template" rows="15" cols="100"></textarea><br />
                  <small id="emailHelp" className="form-text text-muted">ENTER YOUR TEMPLATE - USE PIPE BARS | AROUND FIELDNAMES - IE |NAME| AS A TOKEN FOR A FIELD NAMED 'NAME'.</small>
              </div>
            </Box>

            <Box>

              <Button
                variant="outlined"
                onClick={() => {
                  handleClick();
                }}
              >
                UPDATE
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </div>
    </div>
  )
}