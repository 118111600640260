import { getAllContent, getSchemas, deleteSchema, getContentCountBySchemaId, fetchContentGroupedBySchemaId, fetchSchemaIdCounts, updateSchemaSource } from "../helpers/supabase";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {Link} from 'react-router-dom'
import { DataGrid } from '@mui/x-data-grid';
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Select,
	MenuItem,
	FormHelperText
} from "@mui/material";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { safeJSONParse } from "../helpers/utilities";

const Dashboard = (props) => {

	const navigate = useNavigate()
	const { setAdminSchemaList, arcId } = props;

	const [loading, setLoading] = useState(true);


	const [schemaList, setSchemaList] = useState([]);
	const [contentList, setContentList] = useState([]);
	const [ schemaContentCount, setSchemaContentCount ] = useState([]);

	// state for search Text
	const keysToFilterBy = ['title']; // include the schema obj keys to be filtered upon during search.
	const [filteredSchemaList, setFilteredSchemaList] = useState([])
	const [searchText, setSearchText ] = useState("");

	// state for delete modal
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [schemaName, setSchemaName] = useState('');
	const [schemaID, setSchemaID] = useState('');
	const [confirmedSchemaName, setConfirmedSchemaName] = useState('');
	const [contentCount, setContentCount] = useState(0);
	let isDeleteDisabled = schemaName !== confirmedSchemaName;


	// state for Source modal
	const [showSourceModal, setShowSourceModal] = useState(false);
	const [sourceType, setSourceType] = useState(null)
	const schemaSourceOption = [
		{
		  id: 0,
		  name: 'Arc Embedded',
		  value: 'ARC_EMBEDDED' 
		},
		{
		  id: 1,
		  name: 'Database Only',
		  value: 'DATABASE_ONLY'
		}
	  ]

	async function fetchSchemas() {
		try {
			const schemaData = await getSchemas(arcId);
			const contentData = await getAllContent(arcId);
			const contentCount = await fetchSchemaIdCounts(arcId)
			setSchemaList(schemaData);
			setFilteredSchemaList(schemaData)
			setContentList(contentData);
			setSchemaContentCount(contentCount);
		} catch (error) {
			console.error("Error:", error);
		}
	}

	useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSchemas();
      } catch (error) {
        console.log('Error fetching Schema in dashboard:', error);
        toast.error('Error Fetching Schema in dashboard', {
          autoClose: 5000,
          position: 'top-right',
        });
      }
    };
    fetchData();
  }, []); 


	async function handleRowSelection(rowId) {
		const schema = schemaList.filter((list) => rowId.includes(list.id))
		navigate(`/admin/designer/${rowId}`)
		// const p = { 'schema': schema[0], 'view': 'design' }
		// onSelectSchema(p); // Call the callback function in the parent (admin)
	}

	async function handleEdit() {
		// handle edit view here.
	}

	async function handleView() {
		// handle toggle view here.
	}


	async function toggleDeleteModal() {
		setShowDeleteModal(!showDeleteModal)
	}

	async function toggleEditSourceModal() {
		setShowSourceModal(!showSourceModal)
	}

	function calcSchemaContent(id) {
		for (let i = 0; i < schemaContentCount.length; i++) {

			if (schemaContentCount[i].schemaid === id) {
				return schemaContentCount[i].count
			}
		}

		return '-'
	}



	function filterSchema(e) {
		setSearchText(e.target.value)
		const searchText = e.target.value
		const filteredSchema = schemaList.filter(item =>
			item.schema.title.toLowerCase().includes(searchText.toLowerCase())
		  );
		setFilteredSchemaList(filteredSchema)
	}

	async function handleDelete() {
		// handle edit view here.
		toggleDeleteModal()
		toast.error(`Schema Deleted`, {
			autoClose: 5000,
			position: 'top-right',

		});
		await deleteSchema(schemaID, arcId);
		const updatedSchemaList = schemaList.filter((schema) => schema.id !== schemaID);
		setSchemaList(updatedSchemaList);
		setAdminSchemaList(updatedSchemaList);
		setFilteredSchemaList(updatedSchemaList)
	}

	async function handleEditSource() {
		// handle edit view here.
		toggleEditSourceModal()
		toast.info(`Source Updated`, {
			autoClose: 5000,
			position: 'top-right',

		});

		let value = "ARC_EMBEDDED"

		if(sourceType){
		  value = 'DATABASE_ONLY'
		}

		await updateSchemaSource(schemaID, arcId, value);

		const updatedSchemaList = schemaList.map((schema) => {
			if (schema.id === schemaID) {
				return { ...schema, source: value };
			}
			return schema;
		});
		console.log('US-> ', updatedSchemaList)
		setAdminSchemaList(updatedSchemaList);
		setSchemaList(updatedSchemaList);
		setFilteredSchemaList(updatedSchemaList)
	}


	const columns = [
		{
			field: 'name',
			headerName: 'Schema Name',
			width: 350,
			sortable: true,
			valueGetter: (params) => {
				return params.row.schema ? params.row.schema.title : 'N/A'
			},
			renderCell: (params) => {
				const safeParseData = safeJSONParse(params.row.schema)
				return <div> {safeParseData.title} </div>
			}
		},
		{ field: 'id', headerName: 'Schema ID', width: 200, sortable: true },
		{
			field: 'total_content',
			headerName: '',
			width: 250,
			sortable: true,
			valueGetter: (params) => {
				let count = 1;
				count = calcSchemaContent(params.row.id)
				return count
			}
		},
		{
			field: 'source',
			headerName: 'Source',
			width: 250,
			sortable: true,
			valueGetter: (params) => {
				let value = ''
				const source = params.row.source
				if (source){
					value = source === 'ARC_EMBEDDED' ? 'Arc Embedded Schema' : 'Database Schema'
				}
				return value
			},
			renderCell: (params) => {

				const handleEditSourceClick = (event) => {
					event.stopPropagation(); // Prevent the event from propagating
					// handleDelete(params.row.id);
					console.log('p->', params.row.schema.title)
					
					const value = params.row.source === "ARC_EMBEDDED" ? 0 : 1
					setSourceType(value)
					setSchemaID(params.row.id)
					toggleEditSourceModal()
				};
				return (
					<>
						{ params.row.source ? params.row.source === 'ARC_EMBEDDED' ? "Arc Embedded " : "Database Only" : '-' }
						
						{ params.row.source ?
							<div style={{marginLeft: '5px'}}>
								<Button variant="outlined" color="info" size='small' onClick={(e) => handleEditSourceClick(e)}>
									edit
								</Button>
							</div>:null
						}
						
					</>
				)
			}
		},
		{
			headerName: 'Actions',
			field: 'slug',
			width: 350,
			sortable: true,
			renderCell: (params) => {
				const handleEditClick = (event) => {
					event.stopPropagation(); // Prevent the event from propagating
					handleEdit(params.row.id);
				};

				const handleViewClick = (event) => {
					event.stopPropagation(); // Prevent the event from propagating
					handleView(params.row.id);
				};
				const handleDeleteClick = (event) => {
					event.stopPropagation(); // Prevent the event from propagating
					// handleDelete(params.row.id);
					let count = calcSchemaContent(params.row.id)
					if (typeof count !== 'number'){
						count=0
					}
					setContentCount(count)
					setConfirmedSchemaName(params.row.schema.title)
					setSchemaID(params.row.id)
					
					toggleDeleteModal();
				};
				return (
					<>
						{/* <Button style={{ margin: '5px' }} variant="outlined" color="primary" size='small' onClick={(e) => handleEditClick(e)}>
							Edit
						</Button>
						<Button style={{ marginRight: '5px' }} variant="outlined" color="secondary" size='small' onClick={(e) => handleViewClick(e)}>
							View
						</Button> */}
						<Button variant="outlined" color="error" size='small' onClick={(e) => handleDeleteClick(e)}>
							Delete
						</Button>
					</>
				)
			}
		},

	];



	return (
		<>
			<h3 style={{
			}}>Dashboard</h3>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>

				<Box sx={{ flexGrow: 1 }} />
				<Grid item>
					<Link to={`/admin/logs`}>
						<Button>
							Logs
						</Button>
					</Link>
				</Grid>
				<TextField
					label="Search"
					variant="outlined"
					size="small"
					sx={{ width: '300px' }}
					value={searchText}
					onKeyDown={filterSchema}
					onChange={(e) => filterSchema(e)}
				/>
			</Box>

			<Box id="content-list">
				{ 
					<DataGrid
						sx={{
							minHeight: '200px',
						}}
						rows={filteredSchemaList}
						columns={columns}
						onRowSelectionModelChange={(newRowSelectionModel) => {
							handleRowSelection(newRowSelectionModel);
						}}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 25,
								},
							},
						}}
						pageSizeOptions={[5]}
					/> 
				}

			</Box>

			{/* Modal for Deleting Schema */}
			<div>
				<Dialog  open={showDeleteModal} onClose={toggleDeleteModal}>
					<DialogTitle>Delete Schema "{confirmedSchemaName}"</DialogTitle>
					<DialogContent>
						<DialogContentText>
							"{confirmedSchemaName}" contains {contentCount} contents. <br/>
							Are you sure you want to delete this Schema?
						</DialogContentText>
						<TextField
							label="Enter Schema name."
							variant="outlined"
							size="small"
							sx={{ width: '400px', marginTop:'10px' }}
							value={schemaName}
							onChange={(e) => setSchemaName(e.target.value)} 
						/>
					</DialogContent>
					<DialogActions style={{padding: '20px'}}>
						<Button onClick={toggleDeleteModal} color="primary">
							Cancel
						</Button>
						<Button onClick={handleDelete} disabled={isDeleteDisabled} variant="contained" color="error" autoFocus>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</div>

			{/* Modal for Editing Source */}
			<div>
				<Dialog  open={showSourceModal} onClose={toggleEditSourceModal}>
					<DialogTitle>Edit Source </DialogTitle>
					<DialogContent>
						<Select
							style={{maxWidth:'1000px', width:'100%'}}
							value={sourceType}
							label="Schema Source"
							helperText="ENTER THE SOURCE OF THE SCHEMA."
							onChange={(e) => setSourceType(e.target.value)}
							required
						>
							{ schemaSourceOption.map((source) => (
								<MenuItem key={source.id} value={source.id}>
									{source.name}
								</MenuItem>
							))}
						</Select>

						<FormHelperText style={{ textAlign: 'left' }}>
							Select the Source type for the schema.
						</FormHelperText>
					</DialogContent>
					<DialogActions style={{padding: '20px'}}>
						<Button onClick={toggleEditSourceModal} color="error">
							Cancel
						</Button>
						<Button onClick={handleEditSource} variant="contained" color="info" autoFocus>
							Update
						</Button>
					</DialogActions>
				</Dialog>
			</div>

		</>
	)

}

export default Dashboard;