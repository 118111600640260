import { BrowserRouter } from "react-router-dom";
import '../src/assets/styles/style.css'
import { useState, createContext, useEffect } from 'react'
import { setLicenseKey } from "survey-core";
import { createClient } from '@supabase/supabase-js';
import { getSession } from "./helpers/supabase.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components imports
import Login from "./components/login.jsx";
import Home from "./layouts/home.jsx";

export const UserContext = createContext(null);
const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY);
const has_session = localStorage.getItem('sb-huioktgvbjnujbsjtlhs-auth-token');

export default function App() {

  //TODO: put license key in environment variable
  setLicenseKey("Mzc5ZGM3YjQtMmIyNS00OTI1LWE2ODEtNGVhZjFjOWZlYmUwOzE9MjAyNC0xMC0wMg==");
  const [session, setSession] = useState(null); // Define session state
  const [loading, setLoading] = useState(true)
  const [isValidOrg, setIsValidOrg] = useState(false)
  let subscription;

  useEffect(() => {
    // Function to handle changes in session
    const handleAuthChange = (event, newSession) => {
      setSession(newSession);
    };

    setSession(getSession());

    async function fetchSession() {
      try {
        const userSession = await getSession();
        setSession(userSession);
      } catch (error) {
        console.error("Error fetching session:", error);
      } finally {
        setLoading(false); 
      }
    }

    fetchSession()

    subscription = supabase.auth.onAuthStateChange(handleAuthChange);

    if(has_session){
      setIsValidOrg(true)
    }
    // console.log(has_session, isValidOrg)

    // Cleanup the subscription when the component unmounts
    return () => {
      if (subscription) {
        //subscription.unsubscribe();
      }
    };
  }, [has_session, isValidOrg]); 

  function getRouteElement(){
    if (loading){
      return (<div> loading... </div>)
    }

    if (session && isValidOrg){
      return <Navigate to="/admin"/>
    }
    if(!session){
      return <Login isValidOrg={isValidOrg} setIsValidOrg={setIsValidOrg} setSession={setSession}/>
    }
  }

  return (
    <BrowserRouter>
      <Home has_session={has_session}/>
      <ToastContainer />
    </BrowserRouter>
  );
}
