import React from 'react';


const ContentTable = ({ content }) => {
    const nestedObjects = [];

    const getNestedObjects = (data) => {
        nestedObjects.push(data);

        Object.entries(data).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                getNestedObjects(value);
            }
        });

        nestedObjects.forEach(x => {
            renderTable(x);
        })
    };

    const getNestedObject = (d) => {
        return Object.keys(d).map(key => <div className="col-sm-12">{key}</div>);
    }

    const renderTable = (data) => {
        return Object.entries(data).map(([key, value]) => (
            <React.Fragment key={key}>
                <tr>
                    <td>{key}</td>
                    <td>{typeof value === 'object' ? <div className='row'>{getNestedObject(value)}</div> : value}</td>
                </tr>
            </React.Fragment>
        ));
    };

    if (content) {
        getNestedObjects(content);

        return (
            <div>
                <table className="table table-striped">
                    <tbody>{renderTable(content)}</tbody>
                </table>
            </div>
        );
    } else {
        return null;
    }
};

export default ContentTable;
