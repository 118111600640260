import { useState, useEffect } from 'react'
import SchemaBuilder from "../../src/components/schemabuilder"
import Dashboard from "../../src/components/dashboard";
import Footer from "../../src/components/footer";
import Settings from "../../src/components/settings";
import Help from "../../src/components/help";
import AddSchema from '../../src/components/addschema';
import Header from '../../src/layouts/header'
import { getUser, getUserOrganization } from '../helpers/supabase';
import { Routes, Route } from "react-router-dom";
import SchemaContentLog from '../components/logs/SchemaContentLog';
import UserSection from '../components/UserSection';
import CreateUserSection from '../components/CreateUserSection';
import { useNavigate } from 'react-router-dom';

export default function Admin() {
  const navigate = useNavigate()

  const [currentSchema, setCurrentSchema] = useState(null);
  const [currentView, setCurrentView] = useState('dashboard');
  const [user, setUser] = useState(null);
  const [arcId, setArcId] = useState(null);
  const [schemaList, setSchemaList] = useState([]);

  async function fetchUser() {
    try {
      const user = await getUser();
      setUser(user);
      if (user) {
        const org = await getUserOrganization(user.email);
        const sessionArcId = localStorage.getItem('session_arc_id');
        console.log('org: ', JSON.stringify(org[0].organization.arc_id))
        setArcId(sessionArcId)
      }else{
        navigate('/login/')
      }

    } catch (error) {
      console.log('Error fetching user:', error);
      /* toast.error('Error Fetching User', {
        autoClose: 5000, 
        position: 'top-right', 
      }); */
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUser();
      } catch (error) {
        console.log('Error fetching user:', error);
        /* toast.error('Error Fetching User', {
          autoClose: 5000,
          position: 'top-right',
        }); */
      }
    };
    fetchData();
  }, []);


  const onSelectSchema = (props) => {
    const { schema, view } = props;
    if (schema) {
      setCurrentSchema(schema);
    }
    setCurrentView(view);
  };


  return (
    <>

      {arcId ? <Header arcId={arcId} schemaList={schemaList} setSchemaList={setSchemaList} /> : null}
      <div className="page-wrapper" style={{ minHeight: '100vh' }}>
        <div className="container pt-30">
          <div className="row">

            <Routes>
              {/* <Route path="/" element={session && isValidOrg ? <Admin /> : <Login isValidOrg={isValidOrg} setIsValidOrg={setIsValidOrg} setSession={setSession}/>} /> */}
              {arcId &&
                <>
                  <Route path="/" element={<Dashboard setAdminSchemaList={setSchemaList} arcId={arcId} />} />
                  <Route path="/settings/" element={<Settings />} />
                  <Route path="/add-schema/" element={<AddSchema arcId={arcId} setAdminSchemaList={setSchemaList} setCurrentView={setCurrentView} />} />
                  <Route path="/designer/:schemaId/" element={<SchemaBuilder />} />
                  <Route path="/designer/" element={<SchemaContentLog />} />

                  <Route path="/users/" element={<UserSection />} />
                  <Route path="/create-user/" element={<CreateUserSection />} />
                  
                  <Route path="/logs/" element={<SchemaContentLog />} />
                  <Route path="/designer/:schemaId/logs" element={<SchemaContentLog />} />


                  <Route path="/help/" element={<Help />} />
                </>
              }
            </Routes>

          </div>
          <Footer user={user} arcId={arcId} />
        </div>
      </div>
    </>
  );
}
