import { getUserRole } from "./supabase.js";

export function formatDate(unFormattedDate) {
  const date = new Date(unFormattedDate);

  const formattedDate = date.toenLocalDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const formattedTime = date.toenLocalTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const formattedDateTime = `${formattedDate} ${formattedTime}`;
  return formattedDateTime;
}

export async function checkUserRole(roleToCheck){
  return true;
  try {
    const org_id = localStorage.getItem('org_id')
    const usersInRole = await getUserRole(org_id); 
    if(usersInRole){
      const userRole = usersInRole[0].role.affix
      // console.log('affix->', userRole, roleToCheck)
      const roleExists = roleToCheck.some(role => userRole.includes(role));
      // console.log('exists->', roleExists)
      return roleExists;
    }
    return false

  } catch (error) {
    console.error('Error checking user role:', error);
    return false;
  }
};

export function decapitalizeString(string){
  const words = string.split(' '); 
  const decapitalizedWords = words.map(word => word.charAt(0).toLowerCase() + word.slice(1)); 
  const decapitalizedString = decapitalizedWords.join(' '); 
  return decapitalizedString
}

export function safeJSONParse(data){
  try {

    if( typeof data === 'object' && data != null){
      return data
    }
    console.log(typeof data)

    return JSON.parse(data)
  }catch (error){
    return data
  }
}
