import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const LoginRequired = () => {
    const navigate = useNavigate()
        
    // This could include a function to redirect users to login or other appropriate actions
    const handleRedirect = () => {
        navigate('/login/')  
    };

    return (
        <Box 
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center', 
        }}
        >
        <Typography variant="h2" gutterBottom>
            Please log in to view this page.
        </Typography>
        <Typography variant="body1" gutterBottom>
            Oops! Anonymous users are not allowed to view this page. 
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRedirect} style={{ backgroundColor: '#ff7545' }}>
            Go to Login
        </Button>
        </Box>
    );
};

export default LoginRequired;