import React, { useState, useEffect, useRef } from "react";
import "survey-creator-core/survey-creator-core.min.css";
import '../assets/styles/defaultV2.css';
import { getContentCount, getSchema, setDropDownChangeValue, updateSchema } from "../helpers/supabase";
import { localization } from "survey-creator-core";
import { ReactElementFactory } from "survey-react-ui";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { Serializer, Model } from "survey-core";
import SchemaContent from "./schemacontent";
import SchemaSettings from "./schemasettings";
import { useParams } from 'react-router-dom';
import { Skeleton, Dialog, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { safeJSONParse } from "../helpers/utilities";

export default function SchemaBuilder() {
  const creatorRef = useRef(null);

  const { schemaId } = useParams();
  const previousSchemaRef = useRef()

  // const { schema, arcId } = props;
  
  const [loading, setLoading] = useState(true);
  const [schema, setSchema] = useState({});

  const [currentSchema, setCurrentSchema] = useState({});
  const [prevSchema, setPrevSchema] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [activeTab, setActiveTab] = useState('designer');

  // state modal when option value changes and gives warning.
  const [openModal, setOpenModal] = useState(false);
  const [contentCount, setContentCount] = useState(0);
  
  const arcId = localStorage.getItem('session_arc_id');
  //TODO:- pull site info from arc XP here.
  const baseURL = "https://vc-schemabuilder-server.vercel.app" //process.env.REACT_APP_SERVER_API;
  const siteEndpoint = `/sites/${arcId}`;
  const apiUrl = `${baseURL}${siteEndpoint}`;

  const getSitesData = async () => {
    let siteData = [];
    await fetch(apiUrl, {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
      siteData = data.sites
    })
    .catch(error => {
      console.error('Error fetching data from server:', error);
    });

    return siteData;
  };



  // // parse the schema data if its not been parsed as JSON
  // const parsedSchema = {
  //   ...schema,
  //   schema: safeJSONParse(schema.schema)
  // }
  //   // handle schema

  ReactElementFactory.Instance.registerElement(
    "svc-tab-settings",
    (props) => {
      return ( <SchemaSettings schema={schema} arcId={arcId} /> )
    }
  );

  ReactElementFactory.Instance.registerElement(
    "svc-tab-content",
    (props) => {
      return (<SchemaContent schema={schema} arcId={arcId} source="UI" />);
    }
  );

  useEffect(() => {
    if(currentSchema){
      setPrevSchema(currentSchema) // set current schema to previous and fill new schema to currentSchema
    }
    setCurrentSchema(schema);
  }, [schema]);

  const enLocale = localization.getLocale("en");
  enLocale.ed.addNewQuestion = "Add new field";
  enLocale.ed.Survey = "Schema";
  enLocale.ed.surveySettings = "Schema Settings";
  enLocale.ed.surveySettingsTooltip = "Open schema settings";
  enLocale.ed.surveyTypeName = "Schema";
  enLocale.ed.testSurveyWidth = "Schema width: ";
  enLocale.ed.embedSurvey = "Embed schema";
  enLocale.ed.saveSurvey = "Save schema";
  enLocale.ed.saveSurveyTooltiop = "Save schema";
  enLocale.ed.surveyResults = "Results";
  enLocale.ed.surveyPlaceHolder = "The schema is empty. Drag fields from the toolbox or click the button below.";
  enLocale.ed.lg.empty_tab = "Create a rule to customize the flow of the survey.";
  enLocale.ed.lg.trigger_completeName = "Complete content";
  enLocale.ed.lg.completedHtmlOnConditionName = "Set \"Content Complete\" page markup";
  enLocale.ed.lg.trigger_completeDescription = "When the logic expression returns true then the content is complete.";
  enLocale.ed.lg.trigger_skipDescription = "When the logic expression returns true then skip to / focus the selected question.";
  enLocale.ed.lg.trigger_completeText = "content is complete";
  enLocale.pe.trigger_skipText = "skip to the question {0}";
  enLocale.pe.surveyTitlePlaceholder = "Schema Title";
  enLocale.pe.surveyEditorTitle = "Edit schema settings";
  enLocale.pe.includeIntoResult = "Include in results";
  enLocale.pe.enLocal = "Schema language";
  enLocale.pe.sendResultOnPageNext = "Save partial content in progress";
  enLocale.pe.completeText = "Complete button text";
  enLocale.pe.startSurveyText = "Start button text";
  enLocale.pe.showCompletedPage = "Show the complete page";
  enLocale.pe.maxTimeToFinish = "Time limit to finish (in seconds)";
  enLocale.pe.questionsOnPageMode = "Schema structure";
  enLocale.pe.completedHtml = "Complete page markup";
  enLocale.pe.completedHtmlOnCondition = "Dynamic Complete page markup";
  enLocale.pe.loadingHtml = "Markup to show while schema is loading";
  enLocale.pe.tabs.showOnCompleted = "Complete";
  enLocale.pe.tabs.logo = "Logo in the schema title";
  enLocale.pe.noquestions = "There are no fields in the schema.";
  enLocale.pe.triggerCompleteText = "Copmlete if succeed.";
  enLocale.pe.triggerIsVariable = "Do not put the variable into the result.";
  enLocale.pv.onSurvey = "Continue throughout";
  enLocale.pv.onComplete = "When the content is completed";
  enLocale.pv.survey = "Schema";
  enLocale.ew.showOnPage = "Show schema on page";
  enLocale.ew.showInWindow = "Show schema in a window";
  enLocale.ew.loadFromServer = "Load schema JSON from server";
  enLocale.triggers.completetrigger = "Complete content";
  enLocale.pehelp.cookieName = "Cookies prevent users from filling out the same form twice";
  enLocale.pehelp.keyName = "If the specified column contains identical values, the schema throws the \"Non-unique key value\" error.";
  enLocale.p.surveyId = "Schema id";
  enLocale.p.surveyPostId = "Schema post id";
  enLocale.p.surveyShowDataSaving = "Schema show data saving";
  enLocale.theme.surveyTitle = "Schema title font";
  enLocale.theme.coverInheritWidthFromSurvey = "Same as schema";
  localization.currentLocale = "enLocale";

  useEffect(() => {
    const creatorOptions = {
      showLogicTab: false,
      showJSONEditorTab: false,
      isAutoSave: true,
      showState: true
    };
  
  const creator = new SurveyCreator(creatorOptions);
 
  const contentPlugin = {
    activate: () => { },
    deactivate: () => true
  };

  const settingsPlugin = {
    activate: () => {},
    deactivate: () => true
  };

  creator.addPluginTab("content", contentPlugin, "Content", "svc-tab-content", 4);
  creator.addPluginTab("settings", settingsPlugin, "Settings", "svc-tab-settings", 5);

  creatorRef.current = creator;
  creator.onSurveyPropertyValueChanged.add( async (sender, options) => {
  
    const hasItemValue = options.obj ? options.obj.classMetaData.name : null; 
    if ( hasItemValue && hasItemValue === "itemvalue"){
      const { name, value, oldValue } = options;
      if( name === "text"){
        await setDropDownChangeValue(schemaId, value, oldValue, arcId)
        const contentCount = await getContentCount(schemaId, arcId)
        setContentCount(contentCount)
        toggleModal()
      }
    }

  });

  creator.onQuestionAdded.add(async function(sender, options){
    let question = options.question;
    let questionType = question.getType();

    
    if(questionType === 'matrixdropdown'){
      question.rows.splice(0, question.visibleRows.length);
      question.rows = [{ value: 'Loading...', text:'Loading... Please wait.'}]
      question.name = "Matrix multiselect";

      let dynamicData = [];

      const siteData = []; //await getSitesData();
      question.rows.splice(0, question.visibleRows.length);

      siteData.forEach(site => {
        let newRow = {};
        newRow["value"] = site._id;
        newRow["text"] = site.display_name;
        dynamicData.push(newRow);
      });

      let rowsData = dynamicData.map(item => {
        return {
          value: item.value,
          text: item.text
        };
      });

      question.rows = rowsData;


      const matrixColumnConfig = [
        { name: "Site", cellType: "boolean" },     
        { name: "Button Url", cellType: "text" },     
        { name: "Button Text", cellType: "text" },     
      ];

      question.columns.forEach((column, index) => {
        if (matrixColumnConfig[index]) {
            column.cellType = matrixColumnConfig[index].cellType;
            column.title = matrixColumnConfig[index].name;
        }
      });
    }
  });

    creator.onActiveTabChanged.add((sender, options) => {
      setActiveTab(options.tabName);
    });

    Serializer.addProperty("survey", {
      name: "saveToArcXP:boolean",
      category: "general",
      default: true,
      visibleIndex:1
    });

    creator.onShowingProperty.add(function(sender, options) {

      if (options.property.name === "showTimerPanel" 
          || options.property.name === "showTimerPanelMode"
          || options.property.name === 'maxTimeToFinish'
          || options.property.name === 'maxTimeToFinishPage'
          ) { 
        options.canShow = false; 
      }

    });

    Serializer.removeProperty("survey", "logo");
    Serializer.addProperty("survey", {
      name: "displayField",
      type: "string",
      category: "general",
      visibleIndex: 2 // Adjust the index to position it right after title and description
  });
  


    return () => {
      if (creatorRef.current) {
        creatorRef.current.onActiveTabChanged.remove();
      }
    };
  }, []);

  async function fetchSchemaById() {
    try {
      const data = await getSchema(schemaId, arcId);
      setCurrentSchema(data[0]);
      setSchema(data[0]);
    } catch (error) {
        console.error("Error:", error);
        toast.error('Error Fetching Schema', {
            autoClose: 5000,
            position: 'top-right',
        });
    }
  }

  // Asynchronous Fetching of data from an API with supabase.
  useEffect(() => {
    const fetchData = async () => {

    try {
        await fetchSchemaById(arcId);
        setLoading(false)
    } catch (error) {
       
        toast.error('SCHEMABUILDER: Error Fetching Schema', {
        autoClose: 1000,
        position: 'top-right',
        }); 
    }
    };
    fetchData();
  }, [schemaId]);

  useEffect(() => {
    if (previousSchemaRef.current && previousSchemaRef.current !== schemaId) {
      setLoading(true)
      fetchSchemaById(schemaId)

      if (creatorRef.current) {

        // creatorRef.current.tabName = activeTab;
        setRefreshKey((prevKey) => prevKey + 1); // Update key to force re-render
        if(prevSchema && Object.keys(prevSchema).length > 0){
          creatorRef.current.makeNewViewActive('designer')
          setPrevSchema({})
        } else {
          creatorRef.current.tabName = activeTab;
        }
  
        creatorRef.current.text = JSON.stringify(currentSchema.schema);
      }
      
    }

    previousSchemaRef.current = schemaId;
  }, [schemaId]);

  useEffect(() => {
    if (creatorRef.current) {

      // creatorRef.current.tabName = activeTab;
      setRefreshKey((prevKey) => prevKey + 1); // Update key to force re-render
      if(prevSchema && Object.keys(prevSchema).length > 0){
        creatorRef.current.makeNewViewActive('designer')
        setPrevSchema({})
      } else {
        creatorRef.current.tabName = activeTab;
      }

      creatorRef.current.text = JSON.stringify(currentSchema.schema);
    }
  }, [currentSchema.schema, creatorRef]);


  useEffect(() => {
    if (creatorRef.current) { 
      creatorRef.current.tabName = activeTab;
      creatorRef.current.saveSurveyFunc = (saveNo, callback) => {
        updateSchema(creatorRef.current.JSON, currentSchema.id, arcId);
        callback(saveNo, true);  
      };
    }
  }, [currentSchema.schema, currentSchema.id, arcId, activeTab, prevSchema]);

  function renderLoading(){
    return <Skeleton animation="wave" variant="text" width={500} ></Skeleton>
  }

  function toggleModal(){
    setOpenModal(!openModal)
  }

  return (
    <>
      {creatorRef.current && !loading ? (
        <div style={{ minHeight: "100vh"}}>
          <SurveyCreatorComponent creator={creatorRef.current} />
          <Dialog open={openModal} onClose={toggleModal}>
            <DialogContent>
              <DialogContentText>
                {contentCount} contents are associated with the schema. Contents are modified.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: '20px' }}>
              <Button onClick={(x) => toggleModal()} variant="text" color="info" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        ) : renderLoading()}
    </>
  );
}
