import { useState, useEffect } from 'react'
import { getSingleContent } from '../helpers/supabase';
import { sendMessage, getANSData } from '../helpers/powerup';
import ContentTable from '../components/contenttable';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';

export default function EmbedView() {

    const { arcID } = useParams();
    const [content, setContent] = useState();

    async function fetchData() {
        try {
            const ansData = getANSData(arcID);
            const parsedData = JSON.parse(ansData);
            const id = parsedData.config.contentId._id;
            const c = parsedData.config.content && parsedData.config.content?.length > 0 ? parsedData.config.content : await getSingleContent(id, arcID);
            setContent(c[0].content)
        } catch (error) {
            toast.error('Error Fetching Content', {
                autoClose: 1000,
                position: 'top-right',
            });
        }
    }

    useEffect(() => {
        sendMessage("ready", {
            height: '300px',
            width: '750px',
            overflowY: 'scroll',

        });
        fetchData();
    }, []);

    return (
        <div style={{ display: 'grid', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
            <div style={{ minWidth: '800px', minHeight: '400px', backgroundColor: '#fff' }}>
                <div className="page-wrapper" style={{ border: '1px solid #F3F3F3', minHeight: '700px', backgroundColor: '#fff', paddingLeft: '100px', paddingRight: '100px', overflowY: 'scroll' }}>
                    <div className="container pt-30">
                        <div className="row">
                            {
                               <ContentTable content={content} />
                            } 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
