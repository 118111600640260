import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import logoImg from '../assets/img/schemabuilder_grey.png';
import { getUserOrganization, getUserRole } from '../helpers/supabase';
import { decapitalizeString } from "../helpers/utilities.js";
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY);
export default function Login(props) {

  const { isValidOrg, setIsValidOrg } = props

  const navigate = useNavigate()

  const [session, setSession] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organizationID, setOrganizationID] = useState('');
  const [error, setError] = useState('');

  let subscription;

  const handleLogin = async (event) => {
    try {
      // if (isValidOrg) {
      //   setIsValidOrg(false)
      // }
      event.preventDefault();
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      const user = data.user
      console.log('us', user.email)
      const user_organization = await getUserOrganization(user.email);
      console.log('u', user_organization)
      if (user_organization.length > 0) {
        let flag = false

        for (let i = 0; i < user_organization.length; i++){
          const userOrgID = decapitalizeString(user_organization[i].organization.arc_id);
          const orgID = decapitalizeString(organizationID);
          if(userOrgID === orgID){
            flag = true
            localStorage.setItem('session_arc_id',user_organization[i].organization.arc_id)
            localStorage.setItem('org_id', user_organization[i].id);
            break
          }
        }

        if(flag){
          // setIsValidOrg(true)
          toast.success(`Welcome ${user.email}`, {
            autoClose: 5000,
            position: 'top-right',
          });
          supabase.auth.getSession().then(({ data: { session } }) => {
            localStorage.setItem('session', JSON.stringify(session))
            setSession(localStorage.setItem('session', JSON.stringify(session)))
          });
          navigate('/admin/')
        }else{
          await supabase.auth.signOut();
          localStorage.removeItem('org_id');
          setError('Organization for this User does not match.')
          toast.error(`Organization for this user is not valid.`, {
            autoClose: 5000,
            position: 'top-right',
          });
          // setIsValidOrg(false)
        }

      } else {
        setError('User has no organisation.');
        toast.error(`No Organization for this user.`, {
          autoClose: 5000,
          position: 'top-right',
        });
      }

    } catch (error) {
      console.error('Login error:', error.message);
      setError('Invalid login credentials');
    }
  };


  // useEffect(() => {
  // // Function to handle changes in session
  //   const handleAuthChange = async (event, newSession) => {
  //     // event.preventDefault();
  //     setSession(newSession);
  //     if(newSession){
  //       const user = newSession.user
  //       const user_organsation = await getUserOrganization(user.email)
  //       console.log('user_org:', user_organsation)
  //       // localStorage.setItem('org_id', user_organsation[0].id);
  //     }else{
  //       // localStorage.removeItem('org_id');
  //     }
  //   };

  //   // Initial session check
  //   supabase.auth.getSession().then(({ data: { session } }) => {
  //     setSession(session);
  //   });

  //   // Subscribe to auth state changes
  //   subscription = supabase.auth.onAuthStateChange(handleAuthChange);

  //   // Cleanup the subscription when the component unmounts
  //   return () => {
  //     if (subscription) {
  //       //subscription.unsubscribe();
  //     }
  //   };
  // }, []); // Empty dependency array for componentDidMount behavior


  return (
    <div className="login-wrapper" style={{ minHeight: '100vh' }}>
      <div className="container-login pt-30" style={{textAlign:'center'}}>
      <img src={logoImg} style={{width:'70%'}} />
        <div className="row">
          {/* <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={[]} showLinks={false} /> */}

          <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              label="Username (Email)"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
              fullWidth
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              margin="normal"
              fullWidth
            />
            <TextField
              label="Organization Name"
              variant="outlined"
              type="text"
              value={organizationID}
              onChange={(e) => setOrganizationID(e.target.value)}
              required
              margin="normal"
              fullWidth
            />
            <FormHelperText style={{ textAlign: 'left' }}>
              Enter the organization's name you'd like to have access to.
            </FormHelperText>

            {error && (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">{error}</Alert>
              </Stack>
            )}

            { email && password && organizationID ?
              <Button fullWidth variant="contained" color='secondary' type="submit" style={{ marginTop: '16px', backgroundColor: '#ff7545' }}>
                Login
              </Button> : (
              <Button fullWidth disabled={true} variant="contained" color='secondary' type="submit" style={{ marginTop: '16px' }}>
                Login
              </Button> 
              )
            }
          </form>
        </div>
      </div>
    </div>
  )

}
