import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import { createClient } from '@supabase/supabase-js';
import { getSchemas } from '../helpers/supabase';

import Login from '../../src/components/login';
import Admin from './admin';
import Embed from "../layouts/embed.jsx";
import EmbedView from "../layouts/embedview.jsx";
import EmbedComposer from './embedcomposer.jsx';
import EmbedEdit from './embededit.jsx';
import UnauthorizedPage from '../components/utils/unauthorized';
import LoginRequired from '../components/utils/login_required';

export default function Home(props) {
  const { has_session } = props

  function getRouteElement() {

    if (has_session) {
      return <Navigate to="/admin/" />
    }
    if (!has_session) {
      return <Navigate to="/login" />
    }
  }


  return (
    <Routes>
      {/* <Route path="/" element={session && isValidOrg ? <Admin /> : <Login isValidOrg={isValidOrg} setIsValidOrg={setIsValidOrg} setSession={setSession}/>} /> */}
       <Route path="/" element={getRouteElement()} />

      {/*  embeds routing */}
      <Route path="/embed/:arcID/:schemaID" element={<Embed /> } />
      <Route path="/embedview/:arcID/:schemaID" element={<EmbedView />} />
      <Route path="/embededit/:arcID/:schemaID" element={<EmbedEdit />} />
      <Route path="/embedcomposer/:arcID/:schemaID" element={<EmbedComposer />} />
      
      <Route path="/login" element={<Login />} />
      <Route path="/admin/*" element={<Admin />} />

      {/* error handling routes */}
      <Route path="/401-error" element={<UnauthorizedPage />} />
      <Route path="/login-required" element={<LoginRequired />} />

    </Routes>
  )
}