import { useState, useEffect } from 'react'
import SchemaContent from '../components/schemacontent';
import { sendMessage, getANSData } from '../helpers/powerup';
import { getSchema, getSingleContentNoSchema } from '../helpers/supabase';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { set } from 'lodash';

export default function EmbedEdit() {
    const { arcID, schemaID } = useParams();
    const [content, setContent] = useState(null);
    const [contentId, setContentId] = useState(null);
    const [currentSchema, setCurrentSchema] = useState(null);
    const [message, setMessage] = useState('Loading content...')
    const [parsedData, setParsedData] = useState(null);


    async function fetchData() {
        try {
            // Get the content from the ANS data if it's there, if not, get it from the database
            const ansData = getANSData(arcID);
            const parsedData = JSON.parse(ansData);
            setParsedData(parsedData);
            const id = parsedData.config?.contentId?._id;
            setContentId(id);
            
            let parsedContent = null;
            if (parsedData.config?.content?.singleContent) {
                parsedContent = {
                    id: id,
                    content: parsedData.config?.content?.singleContent?.content ? parsedData.config?.content?.singleContent?.content : null,
                }
            }

            const c = parsedContent ? parsedContent : null // await getSingleContentNoSchema(id, arcID)[0];
            setContent(c)

            const data = await getSchema(schemaID, arcID);
            data && data.length > 0 ? setCurrentSchema(data[0]) : setMessage("Schema not found. Please check the settings of your SchemaBuilder embed.")
        } catch (error) {
            toast.error('Error Fetching Content', {
                autoClose: 1000,
                position: 'top-right',
            });
        }
    }


   /*  async function fetchData() {
        try {
            const data = await getSchema(schemaID, arcID);
            data && data.length > 0 ? setCurrentSchema(data[0]) : setMessage("Schema not found. Please check the settings of your SchemaBuilder embed.")
        } catch (error) {
            setMessage('Error fetching schemas. Please check your connection.');
            console.error("Error fetching schemas:", error.message);
        }

        try {
            const ansData = getANSData(arcID);
            const parsedData = JSON.parse(ansData);
            const id = parsedData.config.contentId._id ? parsedData.config.contentId._id : null;
            setContentId(id);
            let c = parsedData.config?.content?.singleContent;
            if (c) {
                setContent(c);
            }
            else {
                c = await getSingleContent(id, arcID)
            }

            //const h = replacePlaceholdersInTemplates(c);
            //setHtml(h);
        } catch (error) {
            console.log('ERROR:', JSON.stringify(error))
            toast.error('Error Fetching Content', {
                autoClose: 5000,
                position: 'top-right',
            });
        }

    } */

    useEffect(() => {
        function startUp() {
            sendMessage("ready", {
                height: '500px',
                width: '800px'
            });

            fetchData();
        }
        startUp();
    }, []);

    return (
        <div style={{ display: 'grid', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
            <div style={{ width: '100%', minWidth: '800px', backgroundColor: '#fff' }}>
                <div className="page-wrapper" style={{ border: '1px solid #F3F3F3', minHeight: '500px', backgroundColor: '#fff' }}>
                    <div className="container">
                        <div className="row">
                            <div>
                                <div>
                                    {currentSchema ? (
                                        
                                        <SchemaContent schema={currentSchema} arcId={arcID} content={content} contentId={contentId} source="EMBED" style={{ width: '100%' }} />

                                    ) : (
                                        <div>{message}</div>
                                    )}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}