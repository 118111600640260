

const Footer = (props) => {

    const { user, arcId } = props;
    
    return (
        <footer className="footer pl-30 pr-30 mt-30">
            <div className="container">
                <div className="row pb-30">
                    <hr />
                    <div className="col-sm-12">
                        <p>version: 1.03 | {(new Date().getFullYear())} &copy; Versatile Content, LLC. | {user?.email} | {arcId} | {process.env.REACT_APP_SERVER_API}</p>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer;