import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState, useEffect } from 'react';
import { getSchemas, logout, getUser } from '../helpers/supabase';
import { useNavigate } from 'react-router-dom';
import logoImg from '../assets/img/schemabuilder.png';
import { toast } from 'react-toastify';
import { checkUserRole, safeJSONParse } from '../helpers/utilities';
import { Link } from 'react-router-dom';

const Navigation = (props) => {
    const { arcId, schemaList, setSchemaList } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isAdmin, setIsAdmin] = React.useState(false);

    const open = Boolean(anchorEl);
    const navigate = useNavigate(); // useNavigate hook placed at the top level of the functional component

    async function fetchSchemas() {
        try {
            console.log('arc: ', arcId)
            const data = await getSchemas(arcId);
            setSchemaList(data);
            // handleChange(null, null, 'dashboard');
            // console.log('schemaList: ', schemaList)
        } catch (error) {
            console.error("Error:", error);
            toast.error('Error Fetching Schema', {
                autoClose: 1000,
                position: 'top-right',
            });
        }
    }

    // Asynchronous Fetching of data from an API with supabase.
    useEffect(() => {
        const fetchData = async () => {
        try {
            await fetchSchemas(arcId);
        } catch (error) {
            console.log('Error fetching schemas:', error);
           /*  toast.error('Error Fetching User', {
            autoClose: 5000,
            position: 'top-right',
            }); */
        }
        console.log('schemaList: ', schemaList)
        };
        fetchData();
    }, []); 

    useEffect(() => {
        async function checkAdminRole() {
            const adminStatus = await checkUserRole(['A']);
            setIsAdmin(adminStatus);
        }
        checkAdminRole();
    }, []);

    function navigateToLogin() {
        navigate('/login'); // Redirect to the home page after logout
        toast.info('Logged out', {
            autoClose: 1000,
            position: 'top-right',
        });
    }

    const handleChange = (schema, key, view) => {
        const p = { 'schema': schema, 'view': view }
        // onSelectSchema(p); // Call the callback function in the parent
        setAnchorEl(null);
    };

    async function handleLogout() {
        const user = await logout();
        navigateToLogin();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToAddSchema = () => {
        navigate('/admin/add-schema/')
        setAnchorEl(null);
    };

    return (
        <>
            <div className="horizontal-menu" >
                <img className="brand-img" src={logoImg} alt="SchemaBuilder" />
                <div className="nav-wrap" >

                    <Link to="/admin/">
                        <Button
                            id="basic-button-dashboard"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="false"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={() => handleChange(null, null, 'dashboard')}
                            selected={true}
                            style={{ color: 'inherit', minWidth: '150px' }}
                        >
                            Dashboard
                        </Button>
                    </Link>

                    <Button
                        id="basic-button-schemas"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        style={{ color: 'inherit', minWidth: '150px' }}
                    >
                        Schemas
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >   
                        {isAdmin ? (
                            <MenuItem style={{ borderBottom: '1px solid #f3f3f3' }} onClick={redirectToAddSchema}> Add a New Schema </MenuItem>
                        ): null}
                        {schemaList?.map((s, key) => {
                            return (
                                <Link key={key} to={`/admin/designer/${s.id}`}>
                                    <MenuItem key={key} onClick={() => handleChange(s, key, 'design')}> {safeJSONParse(s.schema).title} </MenuItem>
                                </Link>
                            )
                        })}

                    </Menu>

                   {/*  <Link to="users/"> 
                        <Button
                            id="basic-button-dashboard"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="false"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={() => handleChange(null, null, 'dashboard')}
                            selected={true}
                            style={{ color: 'inherit', minWidth: '150px' }}
                        >
                            Users
                        </Button>
                    </Link> */}
                    
                    <Button
                        id="basic-button-logout"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="false"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={() => handleLogout()}
                        className='menuButton'
                        style={{ color: 'inherit', minWidth: '150px' }}
                    >
                        Logout
                    </Button>

                </div>
            </div>

        </>
    )
}

export default Navigation