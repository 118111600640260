import { useState, useEffect } from "react";

// supabase
import { addSchema, getSchemas, checkSlugExists } from "../helpers/supabase"

import { useNavigate } from 'react-router-dom';
// Material UI imports
import { 
  Alert,
  Box, 
  Stack, 
  TextField, 
  Button, 
  Typography,
  Grid,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Misc
import { toast } from 'react-toastify';


export default function AddSchema(props) {

  const { arcId, setAdimSchemaList, setCurrentView } = props;
  const navigate = useNavigate()

  const [ schemaName, setSchemaName] = useState('')
  const [ schemaDesc, setSchemaDesc] = useState('')
  const [ schemaAlias, setSchemaAlias] = useState('')

  // Section of states for Alias Field Validations.
  const [ aliasDisabled, setAliasDisabled] = useState(true)
  const [ aliasExists, setAliasExists] = useState(false)
  const [ invalidAliasFormat, setInvalidAliasFormat] = useState(false)


  const [submitBtnDisabled, setSubmitButtonDisabled] = useState(false)

  const schemaSourceOption = [
    {
      id: 0,
      name: 'Arc Embedded',
      value: 'ARC_EMBEDDED' 
    },
    {
      id: 1,
      name: 'Database Only',
      value: 'DATABASE_ONLY'
    }
  ]
  const [ sourceType, setSourceType] = useState(1)

  async function checkAliasExists(arcID, alias){
    let flag = await checkSlugExists(arcID, alias)
    console.log('came to alias...', flag)
    if(flag) {
      setSubmitButtonDisabled(true)
      setAliasExists(true)
    } else {
      setSubmitButtonDisabled(false)
      setAliasExists(false)
    }
  }

  async function handleClick() {
    await checkAliasExists(schemaAlias)
    const response = addSchema(schemaName, schemaDesc, schemaAlias,  arcId, sourceType);
    await fetchSchemaData();
    toast.success('Schema Added Sucessfully', {
      autoClose: 1000,
      position: 'top-right', 
    });

    navigate('/admin/')
  }

  async function fetchSchemaData() {
    try {
        const data = await getSchemas(arcId);
    } catch (error) {
        console.error("Error:", error);
        toast.error('Error Fetching Schema', {
          autoClose: 1000,
          position: 'top-right', 
        });
    }
  }

  function hasSpacesUppercase(str) {
    const hasSpaces = /\s/.test(str);
    const hasUppercase = /[A-Z]/.test(str); 
  
    return hasSpaces || hasUppercase;
  }

  async function handleAliasEdit() {
    if (hasSpacesUppercase(schemaAlias)){
      setInvalidAliasFormat(true)
      setAliasExists(false)
    }else{
      setAliasDisabled(true)
      await checkAliasExists(arcId, schemaAlias)
      setInvalidAliasFormat(false)
    }

  }

  useEffect(() => {
    if (schemaName === '') {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }, [schemaName]); 


  return (
    <Stack
      spacing={3}
      justifyContent="left"
      alignItems="left"
      style={{ width: '100%', height: '100%' }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          borderRadius: 1,
          p: 2,
          textAlign: 'left',
        }}
      >
        <Typography variant="h6">Create a New Schema</Typography>
        <Stack style={{marginTop: '10px' }} spacing={3}>
        <Grid container spacing={0}>
          <Grid item xs>
            <TextField
              fullWidth
              id="schemaAlias"
              disabled={aliasDisabled}
              label="Schema Alias"
              variant="outlined"
              onChange={(e) => setSchemaAlias(e.target.value)}
              value={schemaAlias}
            />
          </Grid>
          <Grid style={{ marginTop: '10px', marginLeft: '10px' }} item>
          { aliasDisabled && schemaName ?
            (
              <Button onClick={() => setAliasDisabled(false)} variant="contained" color="info">
              Edit
              </Button>
            )

            :
            
            ( schemaName ?
              <Button onClick={() => handleAliasEdit()} variant="contained" color="success">
                OK
              </Button>: null
            )
          }
          </Grid>
        </Grid>
        { aliasExists ? (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">Schema alias already exists. Please pick a different alias.</Alert>
          </Stack>
        ) : null}

        { invalidAliasFormat ? (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">Please make sure the alias does not contain uppercase letters or spaces.</Alert>
          </Stack>
          ) : null
        }

        <TextField
          fullWidth
          id="schemaName"
          label="Name *"
          variant="outlined"
          helperText="ENTER A NAME FOR YOUR SCHEMA. IT MUST BE UNIQUE TO YOUR ORGANIZATION."
          onChange={(e) => {
            const newSchemaName = e.target.value
            setSchemaName(newSchemaName)
            const alias = newSchemaName.replace(/\s+/g, '_').toLowerCase().replace(/_+$/, '')
            setSchemaAlias(alias)
            checkAliasExists(arcId, alias)
          }}
        />

          <Select
            style={{maxWidth:'1000px', width:'100%'}}
            value={sourceType}
            label="Schema Source"
            helperText="ENTER THE SOURCE OF THE SCHEMA."
            onChange={(e) => setSourceType(e.target.value)}
            required
          >
            { schemaSourceOption.map((source) => (
                <MenuItem key={source.id} value={source.id}>
                    {source.name}
                </MenuItem>
            ))}
          </Select>

          <FormHelperText style={{ textAlign: 'left' }}>
              Select the Source type for the schema.
            </FormHelperText>

        <TextField
          multiline
          rows={4}
          fullWidth
          id="description"
          label="Description"
          variant="outlined"
          helperText="ENTER A SHORT BUT MEANINGFUL DESCRIPTION."
          onChange={(e) => setSchemaDesc(e.target.value)}
          />
          <Button disabled={submitBtnDisabled} variant="outlined" onClick={handleClick}>
          Add Schema
        </Button>

      </Stack>
    </Box>
  </Stack>

  )

}