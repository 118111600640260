import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SignalWifiStatusbarNullOutlined } from '@mui/icons-material';

const UnauthorizedPage = () => {
    const navigate = useNavigate()
    const session = localStorage.getItem('session')
    // This could include a function to redirect users to login or other appropriate actions
    const handleRedirect = () => {
        navigate('/login/')
    };
    const goToDashboard = () => {
        navigate('/admin/')
    };

    return (
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
        }}
        >
        <Typography variant="h2" gutterBottom>
            401 - Unauthorized
        </Typography>
        <Typography variant="body1" gutterBottom>
            Oops! You are not authorized to access this page.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRedirect} style={{backgroundColor: '#ff7545' }}>
            Go to Login
        </Button>
        {
            session ?
            <Button variant="contained" color="secondary" onClick={goToDashboard} style={{backgroundColor: '#000', marginTop:'10px' }}>
                Dashboard
            </Button> : SignalWifiStatusbarNullOutlined
        }

        </Box>
    );
};

export default UnauthorizedPage;