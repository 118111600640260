

const Settings = () => {

    return (
        <>
        <h3>Settings</h3></>
    )

}

export default Settings;